// video modal
import React, { useEffect } from "react";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import {
  mainRed,
  mainBlack,
  screen,
  mainYellow,
  mainWhite,
} from "../common/variables";
import ReactPlayer from "react-player";

const Wrapper = styled.div`
  background: rgba(43, 43, 43, 0.92);
  color: ${mainBlack};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${screen.xsmall} {
    background: rgba(43, 43, 43, 0.92);
  }

  .modal-wrapper {
    max-width: calc(1000px + 10vw);
    width: 100%;

    .modal {
      width: 100%;
      background: rgba(43, 43, 43, 0.92);
      position: relative;
      padding-top: 56.25%;

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }

      &__close-btn {
        background: ${mainWhite};
        cursor: pointer;
        border: none;
        border-radius: none;
        position: absolute;
        font-size: 2rem;
        font-weight: 700;
        top: 10px;
        right: 10px;
        color: ${mainYellow};
        outline: none;
        padding: 0;
        line-height: 30px;
        width: 45px;
        height: 45px;
        @media ${screen.xsmall} {
          font-size: 2.2rem;
          top: 0;
          right: 0;
          width: 65px;
          height: 65px;
        }
        @media ${screen.large} {
          font-size: 2.5rem;
          width: 75px;
          height: 75px;
        }
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainRed};
          }
        }
      }
    }
  }
`;

const ModalMember = ({ selectedVideo, setSelectedVideo }) => {
  useEffect(() => {
    // close modal if user clicked outside
    const userClick = (e) => {
      if (e.target.id === "outter-bg-video") {
        setSelectedVideo(null);
      }
    };

    const userPress = (e) => {
      if (e.keyCode === 27) {
        setSelectedVideo(null);
      }
    };

    window.addEventListener("click", userClick);

    window.addEventListener("keyup", userPress);

    return () => {
      window.removeEventListener("click", userClick);
      window.removeEventListener("keyup", userPress);
    };
  }, []);

  // only show modal if selectedVideo not null
  return (
    selectedVideo && (
      <ScrollLocky>
        <Wrapper id="outter-bg-video">
          <div className="modal-wrapper">
            <div className="modal">
              <ReactPlayer
                url={selectedVideo}
                controls={true}
                className="react-player"
              />

              <button
                className="modal__close-btn"
                onClick={() => setSelectedVideo(null)}
              >
                X
              </button>
            </div>
          </div>
        </Wrapper>
      </ScrollLocky>
    )
  );
};

export default ModalMember;
