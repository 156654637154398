import React, { useState, useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainBlack,
  mainRed,
  mainWhite,
  innerWidth,
  lightGrey,
  screen,
  transHover,
} from "../components/common/variables";
import {
  shuffleArray,
  getFirstNameWithGreet,
  reduceWords,
  formatDate,
  isFutureDate,
  toSlug,
} from "../helpers";
import { BsArrowRight } from "react-icons/bs";
import { ImBookmark } from "react-icons/im";
import { BiPlay } from "react-icons/bi";
import SEO from "../components/seo";
import StyledButton from "../components/styled-button";
import Member from "../components/member";
import ModalMember from "../components/modal-member";
import ModalEvent from "../components/modal-event";
import MainBanner from "../components/banner/main";
import ModalVideo from "../components/modal-video";
import ReactPlayer from "react-player";
import Quote from "../components/quote";
import FallbackImgSquare from "../components/fallback-images/fallback-person-member-img-square";

const Wrapper = styled.div`
  color: ${mainBlack};

  .banner {
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .featured-txt {
      .inner-txt {
        .red-heading {
          margin: 15px 0 0 0;
        }

        .btn {
          margin: 20px 0 0 0;
        }
      }
    }
  }

  .meet-choir {
    background: ${lightGrey};
    @media ${screen.xsmall} {
      background: ${mainWhite};
    }
    @media ${screen.small} {
      background: ${lightGrey};
    }

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }
    }

    &__flex {
      display: flex;
      flex-direction: column;
      @media ${screen.small} {
        align-items: center;
        flex-direction: row;
      }

      .txt {
        width: 100%;
        padding: 0 22px;
        text-align: center;
        @media ${screen.small} {
          padding: 0 95px 0 0;
          width: 50%;
          text-align: left;
        }
        @media ${screen.large} {
          padding: 0 140px 0 0;
        }

        .capital-subheading {
          margin: 0 0 22px 0;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        &__description {
          margin: 0 0 30px 0;
          max-width: 675px;
          margin: 0 auto 34px auto;
          @media ${screen.small} {
            margin: 0 0 35px 0;
            max-width: none;
          }
          @media ${screen.large} {
            margin: 0 0 40px 0;
          }

          p {
            font-size: 1rem;
            font-weight: 300;
            @media ${screen.large} {
              font-size: 1.1rem;
            }
          }
        }

        &__btn {
          display: none;
          @media ${screen.small} {
            display: block;
          }
        }
      }

      .members {
        width: 100%;
        @media ${screen.small} {
          width: 50%;
          margin: 0 0 0 22px;
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          @media ${screen.xsmall} {
            margin: 0 -5px;
          }
          @media ${screen.small} {
            margin: 0 -10px;
          }

          .each-member {
            width: 100%;
            display: none;
            @media ${screen.xsmall} {
              display: block;
              width: calc(33.33% - 10px);
              margin: 5px 5px 38px 5px;
            }
            @media ${screen.small} {
              width: calc(50% - 10px);
            }
            @media ${screen.medium} {
              width: calc(33.33% - 10px);
              margin: 5px;
            }

            &:first-child {
              display: block;
            }
          }
        }

        &__btn-only-mobile {
          @media ${screen.xsmall} {
            display: none;
          }

          a {
            text-align: center;
            display: table;
            margin: 16px auto 0 auto;
          }
        }
      }
    }
  }

  .events {
    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        align-items: center;
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        .capital-subheading {
          text-transform: uppercase;
          margin: 0 0 22px 0;
          text-align: center;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.small} {
            text-align: left;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        &--right {
          margin: 0 0 0 10px;

          .event-list {
            &__each {
              border-bottom: 2px ${mainRed} solid;
              position: relative;
              padding: 0 0 60px 0;
              margin: 0 0 28px 0;
              @media ${screen.xsmall} {
                padding: 0 0 16px 0;
              }

              .date-time {
                font-size: 0.88rem;
                font-weight: 600;
                margin: 0 0 8px 0;
                display: block;
                text-transform: uppercase;
                letter-spacing: 1px;
                @media ${screen.xsmall} {
                  font-size: 1rem;
                  margin: 0 0 6px 0;
                }
                @media ${screen.large} {
                  font-size: 1.1rem;
                }
              }

              .venue {
                font-size: 1.22rem;
                font-weight: 400;
                cursor: pointer;
                @media ${screen.xsmall} {
                  font-size: 1.4rem;
                }
                @media ${screen.small} {
                  max-width: 370px;
                }
                @media ${screen.medium} {
                  max-width: 480px;
                }
                @media ${screen.large} {
                  font-size: 1.5rem;
                }
              }

              .absolute-link {
                position: absolute;
                left: 0;
                bottom: 18px;
                display: flex;
                align-items: center;
                color: ${mainBlack};
                @media ${screen.xsmall} {
                  right: 0;
                  transform: translateY(-50%);
                  top: 50%;
                  left: auto;
                  bottom: auto;
                }
                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainRed};
                  }
                }

                span {
                  font-size: 1.22rem;
                  font-weight: 400;
                  @media ${screen.xsmall} {
                    font-size: 1.4rem;
                  }
                  @media ${screen.large} {
                    font-size: 1.5rem;
                  }

                  svg {
                    font-size: 1.6rem;
                    vertical-align: middle;
                    color: ${mainRed};
                    margin: 0 0 0 10px;
                    @media ${screen.xsmall} {
                      font-size: 1.7rem;
                    }
                    @media ${screen.large} {
                      font-size: 1.8rem;
                    }
                  }
                }
              }

              &:last-child {
                margin: 0;
                border-bottom: 0;
                @media ${screen.xsmall} {
                  border-bottom: 2px ${mainRed} solid;
                }
              }
            }
          }
        }
      }

      .btn {
        display: none;
        @media ${screen.small} {
          display: block;
        }

        &--mobile {
          display: block;
          margin: 30px auto 0 auto;
          text-align: center;
          @media ${screen.small} {
            display: none;
          }
        }
      }
    }
  }

  .latest-news {
    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        align-items: center;
        padding: 50px 30px;
      }
      @media ${screen.large} {
        padding: 55px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        .capital-subheading {
          text-align: center;
          margin: 0 0 22px 0;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.small} {
            text-align: left;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        .btn {
          display: none;
          @media ${screen.small} {
            display: block;
          }
        }

        &--right {
          display: flex;
          @media ${screen.small} {
            margin: 0 0 0 10px;
          }

          .each-article {
            background: ${lightGrey};
            position: relative;
            padding: 62px 22px 35px 22px;
            display: flex;
            flex-direction: column;
            @media ${screen.xsmall} {
              padding: 70px 22px 35px 22px;
              width: 50%;
            }
            @media ${screen.small} {
              padding: 100px 22px 22px 22px;
            }

            &__icon-bookmark {
              color: ${mainRed};
              position: absolute;
              right: 15px;
              top: 0;
              font-size: 2.6rem;
              @media ${screen.xsmall} {
                font-size: 3rem;
                right: 27px;
              }
            }

            &__title {
              font-size: 0.88rem;
              font-weight: 600;
              letter-spacing: 1px;
              text-transform: uppercase;
              line-height: 1.3;
              cursor: pointer;
              @media ${screen.xsmall} {
                font-size: 1rem;
              }
            }

            &__img {
              margin: 0 0 15px 0;
              cursor: pointer;
              transition: ${transHover};
              @media ${screen.withCursor} {
                &:hover {
                  opacity: 0.85;
                }
              }
            }

            &__date {
              font-size: 0.75rem;
              display: block;
              margin: 11px 0 13px 0;
              text-transform: uppercase;
              font-weight: 500;
              @media ${screen.xsmall} {
                margin: 9px 0 15px 0;
              }
            }

            &__excerpt {
              font-size: 1.05rem;
              font-weight: 300;
              margin: 0 0 21px 0;
              @media ${screen.large} {
                font-size: 1.1rem;
              }
            }

            .btn {
              margin: auto 0 0 0;
              display: block;
            }

            &:first-child {
              @media ${screen.xsmall} {
                margin: 0 20px 0 0;
              }
              @media ${screen.small} {
                margin: 0 10px 0 0;
              }
            }

            &:last-child {
              display: none;
              @media ${screen.xsmall} {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .sponsors {
    background: ${lightGrey};

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 22px 50px 22px;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        .capital-subheading {
          text-align: center;
          margin: 0 0 22px 0;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.small} {
            text-align: left;
            max-width: 450px;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        &--right {
          @media ${screen.small} {
            margin: 0 0 0 10px;
          }

          .sponsor-list {
            display: flex;
            flex-wrap: wrap;
            @media ${screen.xsmall} {
              margin: 0 -20px;
            }

            &__each {
              width: 100%;
              margin: 0 0 35px 0;
              @media ${screen.xsmall} {
                width: calc(33.33% - 40px);
                margin: 0 20px 20px 20px;
              }
              @media ${screen.small} {
                width: calc(50% - 40px);
                margin: 0 20px 20px 20px;
              }
            }
          }
        }

        .btn {
          display: none;
          @media ${screen.small} {
            display: block;
          }

          &--mobile {
            display: block;
            text-align: center;
            @media ${screen.small} {
              display: none;
            }
          }
        }
      }
    }
  }

  .videos {
    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .capital-subheading {
        text-align: center;
        margin: 0 0 22px 0;
        @media ${screen.xsmall} {
          margin: 0 0 30px 0;
        }
        @media ${screen.large} {
          margin: 0 0 40px 0;
        }
      }

      .video-list {
        display: flex;
        flex-wrap: wrap;
        @media ${screen.small} {
          margin: 0 -20px;
        }

        &__each {
          width: 100%;
          position: relative;
          cursor: pointer;
          margin: 0 0 45px 0;
          @media ${screen.xsmall} {
            width: calc(50% - 40px);
            margin: 0 20px;
          }
          @media ${screen.small} {
            width: calc(33.33% - 40px);
          }

          .react-player {
            pointer-events: none;
          }

          div:first-child {
            height: calc(100px + 25vw) !important;
            width: auto !important;
            @media ${screen.xsmall} {
              height: calc(170px + 7vw) !important;
            }
            @media ${screen.small} {
              height: calc(130px + 7vw) !important;
            }
          }

          .react-player__preview {
            position: relative;

            .icon-play {
              color: ${mainWhite};
              font-size: 7.5rem;
              position: relative;
              z-index: 2;
            }

            &::after {
              content: " ";
              background: rgba(0, 0, 0, 0.55);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
            }
          }

          .video-title {
            font-size: 0.88rem;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: center;
            padding: 0 25px;
            margin: 14px 0 0 0;
            line-height: 1.3;
            @media ${screen.xsmall} {
              font-size: 1rem;
              margin: 17px 0 0 0;
            }
          }

          &:last-child {
            margin: 0;
            @media ${screen.xsmall} {
              display: none;
              margin: 0 20px;
            }
            @media ${screen.small} {
              display: block;
            }
          }
        }
      }

      .flex-btns {
        display: flex;
        flex-direction: column;
        margin: 50px 0 0 0;
        @media ${screen.xsmall} {
          flex-direction: row;
          justify-content: center;
        }

        .btn {
          margin: 0 0 18px 0;
          text-align: center;
          @media ${screen.xsmall} {
            margin: 0 20px;
          }

          a,
          button {
            min-width: 215px;
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  // current member selected state, will open the modal if not null
  const [selectedMember, setSelectedMember] = useState(null);
  // current video selected state, will open the modal if not null
  const [selectedVideo, setSelectedVideo] = useState(null);
  // current event selected state, will open the modal if not null
  const [selectedEvent, setSelectedEvent] = useState(null);

  // all choir member list, saved as state to make it randomize on page load
  const [choirMembers, setChoirMembers] = useState([]);

  const {
    banner_heading,
    banner_subheading,
    banner_image,
    meet_the_choir_description,
    meet_the_choir_heading,
    title_tag,
    meta_description,
    sponsor_list,
    testimonial_author,
    testimonial_message,
    video_list,
  } = data.content.data;

  // events to render - only show if the date is in the future
  const filteredUpcomingEvents = data.upcomingEvents.data.event_list
    .filter((item) => isFutureDate(item.event_date))
    .slice(0, 3);

  // re-order blogs by date based on Prismic
  const blogsByDate = data.latestArticles.edges
    .map((item) => {
      return {
        node: {
          // if edit published date is empty, use the default first publication date from Prismic
          published_date: item.node.data.edit_published_date
            ? Number(item.node.data.edit_published_date.split("-").join(""))
            : Number(
                item.node.first_publication_date
                  .slice(0, 10)
                  .split("-")
                  .join("")
              ),
          first_publication_date: item.node.first_publication_date,
          data: item.node.data,
          id: item.node.id,
        },
      };
    })
    // sort by formatted date as a number (e.g 20201225 - 20191225)
    .sort((a, b) => b.node.published_date - a.node.published_date)
    // slice array to display only 2 blogs
    .slice(0, 2);

  useEffect(() => {
    setChoirMembers(shuffleArray(data.choirMembers.edges));
  }, []);

  return (
    <Layout>
      <SEO
        title={title_tag}
        description={meta_description || "Sydney Street Choir"}
        isHomePage
      />
      <Wrapper>
        <MainBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <h1 className="capital-heading">{banner_heading.text}</h1>
                <h6 className="red-heading">{banner_subheading}</h6>
                <StyledButton className="btn" to="/sponsor-us/">
                  BECOME A SPONSOR
                </StyledButton>
              </div>
            </div>
          </div>
        </MainBanner>

        <section className="meet-choir">
          <div className="inner-wrapper">
            <div className="meet-choir__flex">
              <div className="txt">
                <h2 className="capital-subheading">
                  {meet_the_choir_heading.text}
                </h2>
                <div className="txt__description">
                  <p>{meet_the_choir_description}</p>
                </div>
                <StyledButton className="txt__btn" to="/meet-the-choir/">
                  SEE ALL
                </StyledButton>
              </div>
              <div className="members">
                <ul className="members__list">
                  {choirMembers.slice(0, 6).map((item) => (
                    <Member
                      className="each-member"
                      key={item.node.id}
                      firstName={getFirstNameWithGreet(
                        item.node.data.name.text
                      )}
                      onClick={() => {
                        setSelectedMember({
                          name: item.node.data.name.text,
                          title: item.node.data.position_role,
                          other_title: "",
                          description: item.node.data.description.html,
                          image:
                            item.node.data.photo.url &&
                            item.node.data.photo.url,
                        });
                      }}
                    >
                      <div className="photo">
                        {item.node.data.photo.thumbnails.thumbnail
                          .gatsbyImageData ? (
                          <GatsbyImage
                            image={
                              item.node.data.photo.thumbnails.thumbnail
                                .gatsbyImageData
                            }
                            alt={item.node.data.photo.alt || "Portrait"}
                          />
                        ) : (
                          <FallbackImgSquare />
                        )}
                      </div>
                      <div className="overlay">
                        <h6 className="overlay__name">
                          {item.node.data.name.text}
                        </h6>
                        <button className="overlay__btn">
                          READ THEIR STORY
                        </button>
                      </div>
                    </Member>
                  ))}
                </ul>
                <StyledButton
                  className="members__btn-only-mobile"
                  to="/meet-the-choir/"
                >
                  SEE ALL
                </StyledButton>
              </div>
            </div>
          </div>
        </section>

        {filteredUpcomingEvents.length !== 0 && (
          <section className="events">
            <div className="inner-wrapper">
              <div className="col">
                <h3 className="capital-subheading">UPCOMING EVENTS</h3>
                <StyledButton className="btn" to="/events/">
                  SEE ALL
                </StyledButton>
              </div>
              <div className="col col--right">
                <ul className="event-list">
                  {filteredUpcomingEvents.map((item, i) => (
                    <li className="event-list__each" key={i}>
                      <span className="date-time">
                        {item.event_time}, {formatDate(item.event_date)}
                      </span>
                      <p
                        className="venue"
                        onClick={() => {
                          setSelectedEvent({
                            event_title: item.event_title.text,
                            event_time: item.event_time,
                            event_date: formatDate(item.event_date),
                            event_description: item.event_description,
                            event_address: item.event_address,
                            event_google_map_url: item.google_map_url,
                          });
                        }}
                      >
                        {item.event_title.text}
                      </p>

                      <Link
                        className="absolute-link"
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedEvent({
                            event_title: item.event_title.text,
                            event_time: item.event_time,
                            event_date: formatDate(item.event_date),
                            event_description: item.event_description,
                            event_address: item.event_address,
                            event_google_map_url: item.google_map_url,
                          });
                        }}
                      >
                        <span>More details</span>{" "}
                        <span>
                          <BsArrowRight />
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
                <StyledButton className="btn btn--mobile" to="/events/">
                  SEE ALL
                </StyledButton>
              </div>
            </div>
          </section>
        )}

        <Quote message={testimonial_message} author={testimonial_author} />

        <section className="latest-news">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">LATEST NEWS</h3>
              <StyledButton className="btn" to="/news/">
                SEE ALL
              </StyledButton>
            </div>
            <div className="col col--right">
              {blogsByDate.map((item) => (
                <article className="each-article" key={item.node.id}>
                  <ImBookmark className="each-article__icon-bookmark" />
                  <div
                    className="each-article__img"
                    onClick={() =>
                      navigate(`news/${toSlug(item.node.data.title.text)}`)
                    }
                  >
                    <GatsbyImage
                      image={
                        item.node.data.featured_image.thumbnails.thumbnail
                          .gatsbyImageData
                      }
                      alt={
                        item.node.data.featured_image.all || "Thumbnail preview"
                      }
                    />
                  </div>
                  <h5
                    className="each-article__title"
                    onClick={() =>
                      navigate(`news/${toSlug(item.node.data.title.text)}`)
                    }
                  >
                    {item.node.data.title.text}
                  </h5>

                  <span className="each-article__date">
                    POSTED{" "}
                    {item.node.data.edit_published_date
                      ? formatDate(item.node.data.edit_published_date)
                      : formatDate(item.node.first_publication_date)}
                  </span>
                  <p className="each-article__excerpt">
                    {reduceWords(item.node.data.description.text)}
                  </p>
                  <StyledButton
                    className="btn"
                    to={`news/${toSlug(item.node.data.title.text)}`}
                  >
                    READ MORE
                  </StyledButton>
                </article>
              ))}
            </div>
          </div>
        </section>

        <section className="sponsors">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">PROUDLY SUPPORTED BY</h3>
              <StyledButton className="btn" to="/sponsor-us/">
                BECOME A SPONSOR
              </StyledButton>
            </div>
            <div className="col col--right">
              <ul className="sponsor-list">
                {sponsor_list.map((item, i) => (
                  <li className="sponsor-list__each" key={i}>
                    <a href={item.website_url} target="_blank" rel="noreferrer">
                      <GatsbyImage
                        image={item.logo.gatsbyImageData}
                        alt={item.logo.alt || "Sponsor Brand Logo"}
                      />
                    </a>
                  </li>
                ))}
              </ul>
              <StyledButton className="btn btn--mobile" to="/sponsor-us/">
                BECOME A SPONSOR
              </StyledButton>
            </div>
          </div>
        </section>

        <section className="videos">
          <div className="inner-wrapper">
            <h3 className="capital-subheading">SEE US IN ACTION</h3>
            <ul className="video-list">
              {video_list.map((item, i) => (
                <li
                  className="video-list__each"
                  onClick={() => setSelectedVideo(item.video_url.embed_url)}
                  key={i}
                >
                  <ReactPlayer
                    className="react-player"
                    url={item.video_url.embed_url}
                    light={true}
                    playIcon={<BiPlay className="icon-play" />}
                  />
                  <h6 className="video-title">{item.title}</h6>
                </li>
              ))}
            </ul>

            <div className="flex-btns">
              <StyledButton
                className="btn"
                href="https://www.youtube.com/user/sydneystreetchoir/"
              >
                WATCH MORE
              </StyledButton>
              <StyledButton className="btn" to="/book-the-choir/">
                BOOK THE CHOIR
              </StyledButton>
            </div>
          </div>
        </section>

        <ModalMember
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />

        <ModalEvent
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
        />

        <ModalVideo
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
        />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        banner_heading {
          text
        }
        banner_subheading
        banner_image {
          alt
          url
        }
        meet_the_choir_heading {
          text
        }
        meet_the_choir_description
        testimonial_message
        testimonial_author
        sponsor_list {
          website_url
          logo {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        videos_heading {
          text
        }
        video_list {
          title
          video_url {
            embed_url
          }
        }
        title_tag
        meta_description
      }
    }
    choirMembers: allPrismicChoirMember {
      edges {
        node {
          id
          data {
            name {
              text
            }
            position_role
            description {
              html
            }
            photo {
              alt
              url
              thumbnails {
                thumbnail {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }

    upcomingEvents: prismicUpcomingEvents {
      data {
        event_list {
          event_title {
            text
          }
          event_date
          event_time
          event_address
          google_map_url
          event_description {
            html
          }
        }
      }
    }

    latestArticles: allPrismicArticle {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            edit_published_date
            featured_image {
              alt
              thumbnails {
                thumbnail {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            description {
              text
            }
          }
        }
      }
    }
  }
`;
